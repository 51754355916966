import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  }, {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      auth: true,
    },
  },

  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/users/Users.vue'),
  },
  {
    path: '/challenges',
    name: 'challenges',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/challenges/Challenges.vue'),
  },
  {
    path: '/groups',
    name: 'groups',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/groups/GroupsList.vue'),
  },
  {
    path: '/groups/:id',
    name: 'group-details',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/groups/GroupDetailsPage.vue'),
  },
  {
    path: '/challenges/:id',
    name: 'challenge-details',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/challenges/Challenges copy.vue'),
  },
  {
    path: '/new-challenge',
    name: 'new-challenge',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/challenges/CreateChallenge.vue'),
  },
  {
    path: '/transactions',
    name: 'transactions',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/transactions/Transactions.vue'),
  },
  {
    path: '/withdraws',
    name: 'withdraws',
    meta: {
      auth: true,
    },
    component: () => import('@/views/pages/withdraws/Withdraws.vue'),
  },

  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      auth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/PasswordReset.vue'),
    meta: {
      layout: 'blank',
      auth: false,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
      auth: false,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'PesaSave'
  next()
})

Vue.router = router

export default Vue.router
