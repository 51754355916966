// import {
// //   AbilityBuilder,
// // } from '@casl/ability'

// function builder() {
//   // AbilityBuilder.define(can => {
//   //   can('view challenges')

//   //   // permissions.forEach(permission => {
//   //   //   can(permission, '*')
//   //   // })
//   // })
// }
// export default builder
import { defineAbility } from '@casl/ability'

export default defineAbility((can, cannot) => {
  can('manage', 'all')
  cannot('delete', 'User')
})
