import Vue from 'vue'

// Vue Resource
// import VueResource from 'vue-resource';

// Vue.use(VueResource);
// Vue.http.options.root = process.env.VUE_APP_API_URL;

// Axios
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['X-Authorization'] = process.env.VUE_APP_API_KEY
Vue.use(VueAxios, axios)

export default {
  root: process.env.VUE_APP_API_URL,
}
